export const GitHubIcon = ({ className, fill }) => (
  <svg className={className} fill={fill} height={15} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.01 48.77">
    <path id="e" d="m25.02,0C11.21,0,0,11.18,0,24.98c0,10.77,6.89,20.34,17.12,23.74,1.24.23,1.71-.54,1.71-1.2,0-.6-.02-2.56-.03-4.65-6.95,1.51-8.42-2.95-8.42-2.95-1.14-2.9-2.78-3.66-2.78-3.66-2.27-1.55.17-1.52.17-1.52,1.61.22,3.02,1.17,3.83,2.58,2.23,3.82,5.85,2.72,7.27,2.07.11-1.27.68-2.45,1.59-3.34-5.53-.62-11.39-2.77-11.39-12.35-.04-2.49.89-4.89,2.58-6.72-.77-2.16-.68-4.53.24-6.62,0,0,2.1-.67,6.88,2.56,4.1-1.12,8.42-1.12,12.52,0,4.74-3.22,6.86-2.57,6.86-2.57.92,2.1,1.01,4.47.24,6.62,1.69,1.82,2.61,4.23,2.57,6.71,0,9.6-5.85,11.72-11.41,12.34,1.2,1.23,1.82,2.91,1.69,4.63,0,3.35-.02,6.04-.02,6.86,0,.67.45,1.44,1.71,1.2,13.1-4.38,20.16-18.54,15.79-31.63C45.31,6.88,35.77,0,25.02,0Z" fill-rule="evenodd" />
    <path id="g" d="m9.48,35.89c-.09.14-.28.17-.41.08-.17-.08-.29-.25-.23-.38.09-.13.28-.17.41-.08.16.08.29.25.22.38h0Z" />
    <path id="h" d="m10.49,37.03c-.12.11-.35.06-.51-.12-.16-.13-.19-.35-.07-.52.12-.11.35-.06.51.12s.19.41.07.52Z" />
    <path id="i" d="m11.48,38.47c-.15.11-.41,0-.56-.21s-.15-.49,0-.6.41,0,.56.21.15.5,0,.6Z" />
    <path id="j" d="m12.84,39.86c-.21.15-.5.1-.65-.11,0,0,0,0,0,0-.22-.2-.28-.49-.14-.64s.41-.11.65.1.28.49.14.64h.02Z" />
    <path id="k" d="m14.7,40.66c-.06.19-.34.29-.62.2s-.47-.32-.41-.51.34-.29.62-.2.47.31.41.51Z" />
    <path id="l" d="m16.74,40.81c0,.21-.23.38-.53.38s-.54-.16-.54-.36.23-.38.53-.38.54.16.54.36Z" />
    <path id="m" d="m18.65,40.49c.04.2-.17.41-.46.46s-.56-.07-.6-.27.17-.41.46-.46.56.07.6.27Z" />
  </svg>
);
